.pain-points {
  padding: 20px;
  background-color: #ffffff;
  text-align: center;
}

.pain-points h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: #333;
}

.pain-points__list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.pain-point__list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 30%;
}

.pain-point__issue-icon {
  font-size: 2rem;
  color: #e74c3c;
  margin-bottom: 10px;
}

.pain-point__issue-description {
  font-size: 1.125rem;
  line-height: 1.5;
  color: #555;
}

@media (max-width: 768px) {
  .pain-points__list {
    flex-direction: column;
  }

  .pain-point__list-item {
    width: 100%;
    max-width: 100%;
  }
}
