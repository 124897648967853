.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.overlay__content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  max-width: 600px;
  width: 100%;
}

.overlay__message {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.overlay__button {
  display: inline-block;
  padding: 15px 20px;
  font-size: 16px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.overlay__close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
}

/* Mobile Styles */
@media (max-width: 768px) { 
  .overlay {
    padding: 0;
  }

  .overlay__content {
    width: 90%;
  }

  .overlay__button {
    width: 100%;
    padding: 15px;
  }
}
