.testimonials {
  background-color: #fff;
  padding: 40px 20px;
  text-align: center;
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonials__title {
  font-size: 2em;
}

.testimonial {
  background-color: #f8f8f8;
  padding: 20px;
  margin: 10px;
  width: 250px;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.testimonial p {
  font-style: italic;
}

.testimonial__name {
  margin-top: 10px;
  font-weight: normal;
  font-style: italic;
}
