.main {
  padding: 15px 20px;
  font-size: 16px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.main:hover {
  background-color: rgb(184, 6, 6);
}

@media (max-width: 768px) {
 .main {
  border-radius: 6px;
 } 
}