.funnel-teaser {
  padding: 20px;
}

.funnel-teaser h2 {
  font-size: 2em;
  margin-top: 10px;
}

.funnel-teaser__list {
  list-style: none;
  margin: 40px 0px;
  padding-left: 0px;
}

.funnel-teaser__list-wrapper {
  display: flex;
}

.funnel-teaser__icon {
  width: 30px;
  height: 30px;
  background-color: #2ecc71;
  color: white;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.funnel-teaser__icon::before {
  content: "✓";
}

.funnel-teaser h3 {
  margin: auto 0;
  font-size: 1.2em;
}

.funnel-teaser li p {
  margin-left: 45px;
}

.funnel-teaser__exit {
  font-weight: bold;
  font-size: 1.4em;
}