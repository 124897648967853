.hero {
  background-size: cover;
  text-align: center;
  padding: 50px 20px;
  background: linear-gradient(to bottom, #ececec, #b0b0b0);
}

.hero__left-col {
 width: 50%;
}

.hero-content {
  margin: 0 auto;
  display: flex;
  max-width: 1200px;
  justify-content: center;
}

.hero__title {
  font-size: 3em;
}

.hero__subheading {
  font-size: 1.5em;
}

.hero__disclaimer {
  font-style: italic;
  font-weight: bold;
}

.hero__description-block {
  margin-top: 50px;
}

.hero__right-col img {
  max-width: 500px;
}

@media (max-width: 768px) {
  .hero {
    padding: 20px;
  }
  .hero__left-col {
    width: 100%;
  }
  .hero-content {
    flex-direction: column;
  }
  .hero__right-col img {
    max-width: 100%;
  }
}