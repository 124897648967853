.header {
  background-color: black;
  text-align: center;
}

.header__title {
  margin: 0;
  color: white;
  font-size: 0.95em;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.header__title img {
  max-height: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.header__wrapper {
  position: relative;
}



@media (max-width: 768px) {
/*  */
}