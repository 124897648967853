.stats-section {
  padding: 40px 20px;
}

.stats-row {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stat-item {
  text-align: center;
  font-weight: bold;
}

.number-wrap {
  font-size: 48px;
  color: red;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.number {
  font-size: 48px;
  font-weight: 700;
}

.suffix {
  font-size: 24px;
  margin-left: 4px;
  color: #ff9d3b;
}

.text-below {
  font-size: 0.9em;
  color: #666;
  margin-top: 10px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .stats-row {
    flex-direction: column;
  }

  .stat-item {
    margin-bottom: 20px;
  }

  .stats-section {
    padding: 20px 0;
  }
}