.call-to-action {
  background-color: #f8f8f8;
  text-align: center;
  padding: 50px 20px;
}

.call-to-action h2 {
  font-size: 2em;
}

.call-to-action button {
  border-radius: 6px;
}