.faq-section {
  padding: 20px 0;
  background-color: #ffffff;
  text-align: center;
}

.faq-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.faq-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
  color: #333;
}

.faq-item {
  padding: 20px 0;
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
  cursor: pointer;
}

.faq-question {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.faq-toggle {
  color: red;
  font-weight: bold;
  margin-right: 10px;
  font-size: 2rem;
  width: 20px;
}

.faq-answer {
  padding: 15px 0;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #555;
}

.faq-item.open .faq-answer {
  display: block;
}

.faq-item-cta {
  margin-top: 15px;
}

@media (max-width: 768px) {
  .faq-section {
    padding: 20px;
  }

  .faq-answer {
    width: 90%;
    margin-left: auto;
    padding: 10px 25px;
  }
}
