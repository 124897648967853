.email-form {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  
  .email-form input[type='email'] {
    padding: 10px;
    font-size: 16px;
    width: 300px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 1px solid #9e9e9e;
  }
  
  @media (max-width: 768px) { 
    .email-form {
      flex-direction: column;
    }
    .email-form input[type='email'] {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 15px;
      border-radius: 6px;
      padding: 15px 20px;
    }
  }