.features {
  padding: 20px;
}

.features__title {
  font-size: 2em;
}

.feature {
  display: flex;
  flex-direction: column;
}

.feature__title {
  display: flex;
}

.feature__description {
  margin-left: 45px;
}

.feature h3 {
  margin: auto 0;
  font-size: 1.2em;
}

.features__icon {
  width: 30px;
  height: 30px;
  background-color: #2ecc71;
  color: white;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features__icon::before {
  content: "✓";
}
